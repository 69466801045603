/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useMemo } from 'react';
import Home from 'components/templates/home';
import { NEWS_LIST, VIDEO_BANNERS, VIDEO_SOURCES } from 'libs/constants';
import { NextSeo } from 'next-seo';
import { NextRouter, withRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

interface HomePageProps {
  router: NextRouter;
  lang?: string;
}

const videoBanners = VIDEO_BANNERS;
const videoSources = VIDEO_SOURCES;

const HomePage: React.FC<HomePageProps> = ({ router }) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    if (!router.asPath.startsWith('/th') && !router.asPath.startsWith('/cn')) {
      router.push(`/${i18n.language}`);
    }
  }, [i18n.language, router]);

  // preload videos
  const linkTags: any[] = useMemo(() => {
    const items: any[] = [];
    const videoList = [];

    // if (window.innerWidth <= 640) {
    videoList.push({ file: videoBanners.sp.mp4, type: 'mp4' });
    videoList.push({ file: videoBanners.sp.webm, type: 'webm' });
    videoList.push({ file: videoSources.sp.mp4, type: 'mp4' });
    videoList.push({ file: videoSources.sp.webm, type: 'webm' });
    videoList.forEach((item: any) => {
      items.push({
        rel: 'preload',
        href: item.file,
        as: 'video',
        type: `video/${item.type}`,
      });
    });
    // eslint-disable-next-line no-console
    return items;
  }, []);

  const handleNewsClick = useCallback(
    (slug: string) => {
      const news = NEWS_LIST.filter((x: any) => x.slug === slug).pop();
      if (news) {
        router.push(`/media/news/${slug}`, undefined, {
          scroll: true,
        });
      }
    },
    [router]
  );

  return (
    <>
      <NextSeo additionalLinkTags={linkTags as any} />
      <Home onNewsClick={handleNewsClick} />
    </>
  );
};

export default withRouter(HomePage);
